@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: Overlock;
  }
  /* button:focus {
    outline: none;
  }, */
}

canvas {
  width: 100%;
  height: 100%;
}

#scene-menu {
  background: radial-gradient(
    circle,
    rgba(10, 24, 51, 0.8) 25%,
    rgba(184, 10, 245, 0) 70%
  );
}

#scene-menu:hover {
  background: radial-gradient(
    circle,
    rgba(10, 24, 51, 0.8) 45%,
    rgba(184, 10, 245, 0) 80%
  );
}

button.MuiButtonBase-root:focus {
  outline: none;
}
button.MuiFab-primary {
  background: radial-gradient(
    circle,
    rgba(10, 24, 51, 0.8) 33%,
    rgba(184, 10, 245, 0) 78%
  );
  @apply text-white;
}
button.MuiFab-primary:hover {
  background: radial-gradient(
    circle,
    rgba(10, 24, 51, 0.8) 45%,
    rgba(184, 10, 245, 0) 90%
  );
}
button.MuiFab-root {
  box-shadow: none;
}

.MuiSpeedDialAction-fab:hover {
  background: radial-gradient(
    circle,
    rgba(10, 24, 51, 0.8) 45%,
    rgba(184, 10, 245, 0) 90%
  );
}

.MuiSpeedDialAction-fab {
  background: radial-gradient(
    circle,
    rgba(10, 24, 51, 0.8) 33%,
    rgba(184, 10, 245, 0) 78%
  );
  @apply text-white;
  @apply p-7;
}

.MuiMenuItem-root {
  @apply pr-8;
}

/* .back {
  background: none !important;
  border: solid 1px !important;
} */
