#v3d-container {
  position: absolute;
  left: 0px;
  width: 100%;
  height: 100vh;
}

.fullscreen-button {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  background-size: 100% 100%;
  display: none;
  z-index: 1;
}

.fullscreen-open {
  /* background-image: url("/verge3d/3dcube/media/fullscreen_open.svg"); */
}

.fullscreen-close {
  /* background-image: url("/verge3d/3dcube/media/fullscreen_close.svg"); */
}

* {
  -webkit-tap-highlight-color: rgba(195, 180, 180, 0);
}

.v3d-simple-preloader-background {
  background: #01000a;
}

.v3d-simple-preloader-logo {
  /* background-image: url("/images/worldtreelogo.png"); */
  background-size: contain;
}

.v3d-simple-preloader-bar {
  background: #dddddd4f;
}
